import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import get from 'lodash/get'

import BlockContent from '@sanity/block-content-to-react'

import { media } from '../themes/index'
import { Default, Desktop, Tablet, Phone } from '../components/Responsive'
import Img from 'gatsby-image'

import { Title, Main, Content } from '../components/styles/pages'
import ImageFit from '../components/ImageFit'
import { graphql, useStaticQuery } from 'gatsby'

const StyledImageFit = styled(ImageFit)`
  ${media.tablet`
    margin: 0 auto;
  `}
`

const SubTitle = styled.h2`
  font-weight: 300;
  margin: 0;
  font-size: 3rem;

  ${media.phone`
    font-size: 2rem;
  `}

  ${media.tablet`
    font-size: 2.4rem;
  `}
`

const ContentInfo = styled(Content)`
  display: flex;
  justify-content: center;

  ${media.tablet`
    display: block;
  `}
`

const IntroContainer = styled.div`
  max-width: 600px;
  margin: 0;
  padding-left: 1rem;
  order: 1;
  align-self: center;

  ${media.tablet`
    text-align: center;
    padding-left: 0;
    max-width: none;
  `}
`

const PhotoContainer = styled.div`
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    margin: 0;
  }

  ${media.tablet`
    text-align: center;
    padding-left: 0;
  `}
`

const BioContainer = styled.div`
  text-align: justify;
`

const Credits = styled.span`
  font-size: 0.8rem;
  text-align: right;

  ${media.tablet`
    text-align: center;
  `}
`

const SubSubTitle = styled.h3`
  text-align: center;
`

const BiographyPage = ({ pageResources }) => {
  const queryData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "simonefazio_bio.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      allSanityBiography {
        nodes {
          _rawBio
          _rawPersonalexhibitions
          _rawColletiveexhibitions
        }
      }
    }
  `)

  const _rawBio = get(queryData, 'allSanityBiography.nodes[0]._rawBio')
  const _rawPersonalexhibitions = get(
    queryData,
    'allSanityBiography.nodes[0]._rawPersonalexhibitions'
  )
  const _rawColletiveexhibitions = get(
    queryData,
    'allSanityBiography.nodes[0]._rawColletiveexhibitions'
  )

  const Foto = (
    <Img
      fluid={queryData.file.childImageSharp.fluid}
      alt="Foto di Simone Fazio"
    />
  )
  const FotoFit = (
    <ImageFit width={567} height={850} offsetWidth={'50%'} offsetHeight={380}>
      {Foto}
    </ImageFit>
  )

  return (
    <>
      <Layout pageResources={pageResources}>
        <Main>
          <Title>Biografia</Title>
          <ContentInfo>
            <IntroContainer>
              <SubTitle>Simone Fazio</SubTitle>
            </IntroContainer>
            <PhotoContainer>
              <Phone>{Foto}</Phone>
              <Tablet>
                {
                  <StyledImageFit width={567} height={850} offsetHeight={380}>
                    {Foto}
                  </StyledImageFit>
                }
              </Tablet>
              <Desktop>{FotoFit}</Desktop>
              <Default>{FotoFit}</Default>
              <Credits>Foto di Alberto Toni</Credits>
            </PhotoContainer>
          </ContentInfo>
          <ContentInfo>
            <BioContainer>
              {_rawBio && <BlockContent blocks={_rawBio} />}
            </BioContainer>
          </ContentInfo>
          <ContentInfo>
            <IntroContainer>
              <SubSubTitle>ESPOSIZIONI PERSONALI</SubSubTitle>

              {_rawPersonalexhibitions && (
                <BlockContent blocks={_rawPersonalexhibitions} />
              )}

              <SubSubTitle>ESPOSIZIONI COLLETTIVE</SubSubTitle>

              {_rawColletiveexhibitions && (
                <BlockContent blocks={_rawColletiveexhibitions} />
              )}
            </IntroContainer>
          </ContentInfo>
        </Main>
      </Layout>
    </>
  )
}

export default BiographyPage
